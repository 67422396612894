import { EMPTY, Observable } from 'rxjs';
import { delay, expand, reduce } from 'rxjs/operators';

export interface Paginated<Data> extends Pagination {
  data: Data[];
}

export interface Pagination {
  total?: number;
  skip: number;
  limit: number;
}

/**
 * Load all paginated data and return plain array of results.
 *
 * @param operationFactory
 * @param config
 */
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function loadAllPaginated<T>(
  operationFactory: (pagination: Pagination, params?: any) => Observable<Paginated<T>>,
  config?: {
    pageSize?: number;
  }
): Observable<T[]> {
  const pageSize = config?.pageSize ?? 100;
  return operationFactory({
    limit: pageSize,
    skip: 0
  }).pipe(
    expand((result: Paginated<T>) => {
      const { limit, skip, total } = result;
      const loadedCount = Math.min(skip + limit, total);
      if (loadedCount < total) {
        return operationFactory({ skip: loadedCount, limit: pageSize }).pipe(delay(100));
      } else {
        return EMPTY;
      }
    }),
    reduce((acc: any[], value: Paginated<T>) => {
      return acc.concat(value.data);
    }, [])
  );
}
