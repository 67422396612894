import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { loadAllPaginated } from '@app/utils/load.utils';

import { Paginated } from '../../model/Paginated';
import { SafeAny } from '../../model/SafeAny';

export class MapperService<T = any> {
  protected baseUrl?: string;
  protected authToken?: string;

  constructor(protected endpoint: string, protected http: HttpClient) {
    this.baseUrl = environment.baseUrl;
    this.authToken = environment.authToken;
  }

  protected get(endpoint?: string) {
    return (id?: string): Observable<T> => {
      return this.http
        .get<T>(this.getUrl(id, endpoint), {
          withCredentials: true,
          headers: this.getHeaders(),
          observe: 'response',
          responseType: 'json'
        })
        .pipe(map((res) => res.body as T));
    };
  }

  protected list<U = T>(endpoint: string) {
    return (params?: any): Observable<Paginated<U>> => {
      return this.http
        .get<Paginated<U>>(this.getUrl('', endpoint), {
          withCredentials: true,
          headers: this.getHeaders(),
          observe: 'response',
          responseType: 'json',
          params
        })
        .pipe(map((res) => res.body as Paginated<U>));
    };
  }

  protected listAll<U = T>(endpoint: string, params?: SafeAny) {
    const opFactory = (pagination) =>
      this.list(endpoint)({
        ...params,
        $skip: pagination.skip,
        $limit: pagination.limit
      });

    return loadAllPaginated(opFactory, { pageSize: 50 });
  }

  protected create<U = T>(endpoint: string, options?: any, contentType?: string) {
    return (body?: Partial<U>, params?: any): Observable<U> => {
      return this.http
        .post<U>(this.getUrl('', endpoint), body, {
          withCredentials: true,
          headers: this.getHeaders(contentType),
          observe: 'response',
          responseType: 'json',
          params,
          ...options
        })
        .pipe(map((res) => (res as any).body as any));
    };
  }

  protected update(endpoint?: string) {
    return (id: string, body: Partial<T>, params?: any): Observable<T> => {
      return this.http
        .patch<T>(this.getUrl(id, endpoint), body, {
          withCredentials: true,
          headers: this.getHeaders(),
          observe: 'response',
          responseType: 'json',
          params
        })
        .pipe(map((res) => res?.body as T));
    };
  }

  protected delete(endpoint: string) {
    return (id: string, params?: any): Observable<T> => {
      return this.http
        .delete<T>(this.getUrl(id, endpoint), {
          withCredentials: true,
          headers: this.getHeaders(),
          observe: 'response',
          responseType: 'json',
          params
        })
        .pipe(map((res) => res.body as T));
    };
  }

  protected getUrl(param?: string, endpoint?: string) {
    let url = `${this.baseUrl}`;
    if (this.endpoint) {
      url += `/${this.endpoint}`;
    }

    if (endpoint) {
      url += `/${endpoint}`;
    }
    if (param) {
      url += `/${param}`;
    }
    return url;
  }

  protected getHeaders(contentType?: string) {
    return {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Authorization: `Basic ${this.authToken}`,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Content-Type': contentType ?? 'application/json'
    };
  }
}
