export const environment = {
  production: true,
  baseUrl: 'https://api.staging.videobomb.com',
  hostUrl: 'https://staging.videobomb.com',
  appHostUrl: 'https://app.staging.videobomb.com',
  authToken: 'dmlkZW9ib21iOnVhWWNNeUsuSjlkWDQ0amNNN3NrXzN5ZA==',
  stripeKey:
    'pk_test_51LD28IGizau5798gO1hmD96E3WAyHshAQzlm46TlePEmscKYiwftYauq16F6MK8t3yuQyNus2P0V6OO5Y5x7f3TW00wOAz8z6G',
  recaptchaSiteKey: '6LcVSSEhAAAAADrGvBlYr5cc9OaPMQ32tTN4rGUP'
};
